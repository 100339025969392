<template>
  <div class="card">
    <div
      v-b-toggle="`collapsePublicationFeatures`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Features</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapsePublicationFeatures`"
      visible
    >
      <div class="card-content collapse show">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <h4 class="mb-1">
                Memoir
              </h4>
              <div class="row">
                <div class="col-6">
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id=""
                        v-model="publication.show_memoir"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label"
                        for=""
                      >{{ labels['features.memoir_pre_select'] }}</label>
                    </div>
                    <p><small>{{ labels['features.memoir_pre_select_description'] }}</small></p>
                  </div>
                </div>
                <div class="mb-1 col-6">
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['features.order_in_memoir'] }}</label>
                  <input
                    v-model="publication.order_memoir"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <h4 class="mb-1">
                ICREA
              </h4>
              <div
                v-if="loggedUser.roles && (loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin'))"
                class="row"
              >
                <div class="col">
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id=""
                        v-model="publication.icrea_appointment"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label"
                        for=""
                      >{{ labels['features.prior_icrea_appointment'] }}</label>
                    </div>
                    <p><small>{{ labels['features.prior_icrea_appointment_select'] }}</small></p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id=""
                        v-model="publication.show_abridged_cv"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label"
                        for=""
                      >{{ labels['features.select_publication_to_cv'] }}</label>
                    </div>
                    <p><small>{{ labels['features.select_publication_to_cv_description'] }}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div
            v-if="loggedUser.roles && (loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin'))"
            class="row"
          >
            <div class="col-sm-6">
              <h4 class="mb-1">
                WOS
              </h4>
              <div class="row">
                <div class="col">
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id=""
                        v-model="publication.indexed_wos"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label"
                        for=""
                      >{{ labels['features.indexed_in_wos'] }}</label>
                    </div>
                    <p><small>{{ labels['features.indexed_in_wos_description'] }}</small></p>
                  </div>
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id=""
                        v-model="publication.verified_wos"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label"
                        for=""
                      >{{ labels['features.verified_wos'] }}</label>
                    </div>
                    <p><small>{{ labels['features.verified_wos_description'] }}</small></p>
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >{{ labels['features.comments'] }}</label>
                    <textarea
                      v-model="publication.comments_wos"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <h4 class="mb-1">
                Signed as ICREA
              </h4>
              <div class="row">
                <div class="col">
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id=""
                        v-model="publication.signed_icrea"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label"
                        for=""
                      >{{ labels['features.signed_icrea'] }}</label>
                    </div>
                    <p><small>{{ labels['features.signed_icrea_description'] }}</small></p>
                  </div>
                  <div class="mb-1">
                    <div class="form-check">
                      <input
                        id=""
                        v-model="publication.verified_icrea"
                        class="form-check-input"
                        type="checkbox"
                      >
                      <label
                        class="form-check-label"
                        for=""
                      >{{ labels['features.verified_signature'] }}</label>
                    </div>
                    <p><small>{{ labels['features.verified_signature_icrea'] }}</small></p>
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >{{ labels['features.comments'] }}</label>
                    <textarea
                      v-model="publication.comments_icrea"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  computed: {
    ...mapGetters({
      publication: 'publications/item',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
    }),
  },
}
</script>
